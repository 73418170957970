<p-card>
    <div
        id="hero"
        class="flex flex-col pt-6 px-6 lg:px-20 overflow-hidden"
        style="
            background: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.2),
                    rgba(255, 255, 255, 0.2)
                ),
                radial-gradient(
                    77.36% 256.97% at 77.36% 57.52%,
                    rgb(238, 239, 175) 0%,
                    rgb(205, 195, 250) 100%
                );
            clip-path: ellipse(150% 87% at 93% 13%);
        "
    >
        <div class="mx-6 md:mx-20 mt-0 md:mt-6">
            <div class="text-700 text-center">
                <div
                    class="font-bold mb-3"
                    [ngClass]="{
                        'text-blue-600': !layoutService.isDarkTheme(),
                        'text-blue-950': layoutService.isDarkTheme()
                    }"
                >
                    &nbsp;POWERED BY TNP
                </div>
                <div class="text-blue-950 font-bold text-5xl mb-3"
                    [ngClass]="{
                        'text-blue-950': layoutService.isDarkTheme()
                    }"
                >
                    Bienvenido al Customer Manager
                </div>
                <div class="text-700 text-2xl mb-5"
                    [ngClass]="{
                        'text-blue-950': layoutService.isDarkTheme()
                    }"
                >
                    Interfaz gráfica para la gestión de clientes (organizaciones), usuarios y roles.
                </div>
                <button
                    pButton
                    pRipple
                    label="Action now"
                    icon="pi pi-send"
                    class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
                ></button>
            </div>
        </div>
        <div class="flex justify-center md:justify-end">
            <img
                src="assets/images/TNP_logo_DARK.png"
                alt="TNP LOgo"
                class="w-1/2 md:w-1/3 lg:w-1/4"
            />
        </div>
    </div>

    <div id="features" class="py-6 px-6 lg:px-20 mt-2 mx-0 lg:mx-20">
        <div class="grid grid-cols-12 gap-4 justify-center">
            <div class="col-span-12 text-center mt-20 mb-6">
                <div
                    class="text-surface-900 dark:text-surface-0 font-normal mb-2 text-4xl"
                >
                    Marvelous Features
                </div>
                <span class="text-muted-color text-2xl"
                    >Placerat in egestas erat...</span
                >
            </div>

            <div
                class="col-span-12 md:col-span-12 lg:col-span-4 p-0 lg:pr-8 lg:pb-8 mt-6 lg:mt-0"
            >
                <div
                    style="
                        height: 160px;
                        padding: 2px;
                        border-radius: 10px;
                        background: linear-gradient(
                                90deg,
                                rgba(253, 228, 165, 0.2),
                                rgba(187, 199, 205, 0.2)
                            ),
                            linear-gradient(
                                180deg,
                                rgba(253, 228, 165, 0.2),
                                rgba(187, 199, 205, 0.2)
                            );
                    "
                >
                    <div
                        class="p-4 bg-surface-0 dark:bg-surface-900 h-full"
                        style="border-radius: 8px"
                    >
                        <div
                            class="flex items-center justify-center bg-yellow-200 mb-4"
                            style="
                                width: 3.5rem;
                                height: 3.5rem;
                                border-radius: 10px;
                            "
                        >
                            <i
                                class="pi pi-fw pi-users !text-2xl text-yellow-700"
                            ></i>
                        </div>
                        <h5 class="mb-2 text-surface-900 dark:text-surface-0">
                            Easy to Use
                        </h5>
                        <span class="text-surface-600 dark:text-surface-200"
                            >Posuere morbi leo urna molestie.</span
                        >
                    </div>
                </div>
            </div>

            <div
                class="col-span-12 md:col-span-12 lg:col-span-4 p-0 lg:pr-8 lg:pb-8 mt-6 lg:mt-0"
            >
                <div
                    style="
                        height: 160px;
                        padding: 2px;
                        border-radius: 10px;
                        background: linear-gradient(
                                90deg,
                                rgba(145, 226, 237, 0.2),
                                rgba(251, 199, 145, 0.2)
                            ),
                            linear-gradient(
                                180deg,
                                rgba(253, 228, 165, 0.2),
                                rgba(172, 180, 223, 0.2)
                            );
                    "
                >
                    <div
                        class="p-4 bg-surface-0 dark:bg-surface-900 h-full"
                        style="border-radius: 8px"
                    >
                        <div
                            class="flex items-center justify-center bg-cyan-200 mb-4"
                            style="
                                width: 3.5rem;
                                height: 3.5rem;
                                border-radius: 10px;
                            "
                        >
                            <i
                                class="pi pi-fw pi-palette !text-2xl text-cyan-700"
                            ></i>
                        </div>
                        <h5 class="mb-2 text-surface-900 dark:text-surface-0">
                            Fresh Design
                        </h5>
                        <span class="text-surface-600 dark:text-surface-200"
                            >Semper risus in hendrerit.</span
                        >
                    </div>
                </div>
            </div>

            <div
                class="col-span-12 md:col-span-12 lg:col-span-4 p-0 lg:pb-8 mt-6 lg:mt-0"
            >
                <div
                    style="
                        height: 160px;
                        padding: 2px;
                        border-radius: 10px;
                        background: linear-gradient(
                                90deg,
                                rgba(145, 226, 237, 0.2),
                                rgba(172, 180, 223, 0.2)
                            ),
                            linear-gradient(
                                180deg,
                                rgba(172, 180, 223, 0.2),
                                rgba(246, 158, 188, 0.2)
                            );
                    "
                >
                    <div
                        class="p-4 bg-surface-0 dark:bg-surface-900 h-full"
                        style="border-radius: 8px"
                    >
                        <div
                            class="flex items-center justify-center bg-indigo-200"
                            style="
                                width: 3.5rem;
                                height: 3.5rem;
                                border-radius: 10px;
                            "
                        >
                            <i
                                class="pi pi-fw pi-map !text-2xl text-indigo-700"
                            ></i>
                        </div>
                        <div
                            class="mt-6 mb-1 text-surface-900 dark:text-surface-0 text-xl font-semibold"
                        >
                            Well Documented
                        </div>
                        <span class="text-surface-600 dark:text-surface-200"
                            >Non arcu risus quis varius quam quisque.</span
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</p-card>
