// organization.ts

// Enum para el tipo de organización
export enum OrganizationType {
  Partner = 'Partner',
  Customer = 'Customer'
}

// (Opcional) Modelo para OrganizationPermission, utilizado en el modelo Organization.
// Puedes ajustar o eliminar estos modelos si ya los tienes definidos en otro lugar.
export enum PermissionType {
  Read = 'Read',
  Write = 'Write'
}

export interface OrganizationPermission {
  id: string;
  userId?: string;
  // user?: User; // Defínelo si es necesario
  userGroupId?: string;
  // userGroup?: UserGroup; // Defínelo si es necesario
  permissionType: PermissionType;
  organizationId: string;
  // organization?: Organization; // Evita la recursividad o ajústalo según tus necesidades
}

// Modelo principal para Organization
export interface Organization {
  id: string;
  createdAt: string; // ISO string (date-time)
  updatedAt: string; // ISO string (date-time)
  createdBy?: string;
  name?: string;
  nif?: string;
  invoiceAddress?: string;
  contactEmail?: string;
  contactPhone?: string;
  contactName?: string;
  licenseType?: string;
  parentOrganizationId?: string;
  parentOrganization?: Organization;
  metadata?: any;
  organizationType?: OrganizationType;
  organizationPermissions?: OrganizationPermission[];
}

// Modelo para la creación de una organización
export interface OrganizationCreateDto {
  name?: string;
  nif?: string;
  invoiceAddress?: string;
  contactEmail?: string;
  contactPhone?: string;
  contactName?: string;
  licenseType?: string;
  metadata?: any;
}

// Modelo para la respuesta paginada de organizaciones
export interface OrganizationItemsPaginated {
  totalCount: number;
  page: number;
  pageSize: number;
  items: Organization[];
}
