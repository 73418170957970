import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StyleClassModule } from 'primeng/styleclass';
import { Router, RouterModule } from '@angular/router';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { DividerModule } from 'primeng/divider';
import { DialogModule } from 'primeng/dialog';
import { ToggleSwitchModule } from 'primeng/toggleswitch';
import { LayoutService } from '../../../services/layout.service';
import { AuthService } from '../../../services/auth.service';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-topbar',
    standalone: true,
    imports: [
        RouterModule,
        FormsModule,
        StyleClassModule,
        ButtonModule,
        RippleModule,
        MenuModule,
        DialogModule,
        DividerModule,
        ToggleSwitchModule,
        CommonModule,
    ],
    template: `
        <div class="layout-topbar">
            <div class="layout-topbar-logo-container">
                @if (showSidebarButton) {
                <button
                    class="layout-menu-button layout-topbar-action"
                    (click)="layoutService.onMenuToggle()"
                >
                    <i class="pi pi-bars"></i>
                </button>
                }
                <a class="layout-topbar-logo" routerLink="/">
                    @if (layoutService.isDarkTheme()) {
                    <img
                        src="assets/images/TNP_logo_WHITE.png"
                        alt="TNP Logo"
                        class="h-12 mr-2"
                    />
                    } @else {
                    <img
                        src="assets/images/TNP_logo_DARK.png"
                        alt="TNP Logo"
                        class="h-12 mr-2"
                    />
                    }
                    <span>CustomerManager</span>
                </a>
            </div>

            <div class="layout-topbar-actions">
                <div class="layout-topbar-actions">
                    <button
                        class="layout-topbar-menu-button layout-topbar-action"
                        pStyleClass="@next"
                        enterFromClass="hidden"
                        enterActiveClass="animate-scalein"
                        leaveToClass="hidden"
                        leaveActiveClass="animate-fadeout"
                        [hideOnOutsideClick]="true"
                    >
                        <i class="pi pi-ellipsis-v"></i>
                    </button>
                    <div class="layout-topbar-menu hidden lg:block">
                        <div class="layout-topbar-menu-content">
                            @for (action of actions; track $index) { @if
                            (action.type === 'element') {
                            <button
                                type="button"
                                class="layout-topbar-action-element"
                                routerLink="{{ action.route }}"
                                (click)="action.command()"
                            >
                                <span>{{ action.label }}</span>
                            </button>
                            } @else if (action.type === 'theme') {
                            <button
                                type="button"
                                class="layout-topbar-action"
                                (click)="toggleDarkMode()"
                            >
                                <i
                                    [ngClass]="{
                                        'pi ': true,
                                        'pi-moon': layoutService.isDarkTheme(),
                                        'pi-sun': !layoutService.isDarkTheme()
                                    }"
                                ></i>
                                <span>{{ action.label }}</span>
                            </button>
                            } @else {
                            <button
                                type="button"
                                class="layout-topbar-action"
                                (click)="action.command()"
                            >
                                <i class="{{ action.icon }}"></i>
                                <span>{{ action.label }}</span>
                            </button>
                            } }
                            
                            <button
                                type="button"
                                class="layout-topbar-action"
                                (click)="showSettingsDialog()"
                            >
                                <i class="pi pi-cog"></i>
                                <span>Settings</span>
                            </button>
                            
                            <p-menu
                                [showTransitionOptions]="'0ms'"
                                [hideTransitionOptions]="'0ms'"
                                #menu
                                [popup]="true"
                                [model]="userItems"
                                id="menuUser"
                            ></p-menu>
                            <button
                                class="p-link layout-topbar-action"
                                (click)="menu.toggle($event)"
                            >
                                <i class="pi pi-user"></i>
                                <span>Profile</span>
                            </button>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p-dialog
            header="Settings"
            [modal]="true"
            [(visible)]="settingsDialogVisible"
            [style]="{ width: '50rem' }"
            [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
            [maximizable]="true"
        >
            <p-divider align="left" type="solid">
                <b>Theme</b>
            </p-divider>
            <div class="flex items-center justify-between">
                <div class="text-md">Dark Theme</div>
                <p-toggleswitch [(ngModel)]="isDarkTheme" (onChange)="toggleDarkMode()">
                    <ng-template #handle let-checked="checked">
                        <i [ngClass]="['!text-xs', 'pi', checked ? 'pi-moon' : 'pi-sun']"></i>
                    </ng-template>
                </p-toggleswitch>
            </div>
        </p-dialog>
    `,
})
export class TopbarComponent implements OnInit {
    @Input() showSidebarButton = true;
    @Input() actions: TopbarActions[] = [];

    userItems = [
        {
            label: 'User Profile',
            icon: 'pi pi-user',
            routerLink: ['/user/profile'],
        },
        {
            label: 'Logout',
            icon: 'pi pi-power-off',
            command: () => {
                this.authService.logout();
                this.router.navigate(['/login']);
            },
        },
    ];
    isDarkTheme = false;
    settingsDialogVisible = false;
    

    constructor(
        public layoutService: LayoutService,
        public authService: AuthService,
        public router: Router
    ) {}

    ngOnInit(): void {
        this.isDarkTheme = this.layoutService.isDarkTheme() || false;
    }

    showSettingsDialog() {
        this.settingsDialogVisible = true;
    }

    toggleDarkMode() {
        this.layoutService.layoutConfig.update((state) => ({
            ...state,
            darkTheme: !state.darkTheme,
        }));
        localStorage.setItem(
            'theme',
            this.layoutService.isDarkTheme() ? 'dark' : 'light'
        );
    }
}

export interface TopbarActions {
    label: string;
    type: string;
    icon?: string;
    route?: string;
    command: () => void;
}
