import {Component, inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { CardModule } from 'primeng/card';
import { LayoutService } from '../../services/layout.service';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-inicio',
    standalone: true,
    imports: [CommonModule, ButtonModule, DividerModule, CardModule],
    templateUrl: './inicio.component.html',
    styleUrls: []
})
export class InicioComponent implements OnInit {

    public readonly layoutService: LayoutService = inject(LayoutService);
    private readonly authService: AuthService = inject(AuthService);

    ngOnInit(): void {
        this.authService.getUserInfo().then(userInfo => {
            console.log('User Info:', userInfo);
        }).catch(error => {
            console.error('Error al obtener el usuario:', error);
        });
    }
}
