// auth.guard.ts
import { Injectable, inject } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import Keycloak from 'keycloak-js';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  
  private readonly keycloak = inject<Keycloak>(Keycloak);
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);

  canActivate(): boolean {
    const isAuthenticated = !!this.keycloak.authenticated;

    if (!isAuthenticated) {
      this.keycloak.login(); // Redirige automáticamente a Keycloak si no está logueado
      return false;
    }
    if (this.authService.isUserInfoSaved()) {
      return true;
    }

    this.authService.getCurrentUser()
      .then(userInfo => {
        this.authService.saveUserInfo(userInfo);
      })
      .catch(error => {
        console.error('Error al obtener el usuario actual', error);
      });

    return true;
  }
}
