import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, PLATFORM_ID, ElementRef, booleanAttribute, Directive, Input, NgModule } from '@angular/core';
import { BaseComponent } from 'primeng/basecomponent';
import { DomHandler } from 'primeng/dom';

/**
 * AutoFocus manages focus on focusable element on load.
 * @group Components
 */
class AutoFocus extends BaseComponent {
  /**
   * When present, it specifies that the component should automatically get focus on load.
   * @deprecated use [pAutoFocus]="true"
   * @group Props
   */
  autofocus = false;
  /**
   * When present, it specifies that the component should automatically get focus on load.
   * @group Props
   */
  _autofocus = false;
  focused = false;
  platformId = inject(PLATFORM_ID);
  document = inject(DOCUMENT);
  host = inject(ElementRef);
  ngAfterContentChecked() {
    // This sets the `attr.autofocus` which is different than the Input `autofocus` attribute.
    if (this.autofocus === false) {
      this.host.nativeElement.removeAttribute('autofocus');
    } else {
      this.host.nativeElement.setAttribute('autofocus', true);
    }
    if (!this.focused) {
      this.autoFocus();
    }
  }
  ngAfterViewChecked() {
    if (!this.focused) {
      this.autoFocus();
    }
  }
  autoFocus() {
    if (isPlatformBrowser(this.platformId) && this._autofocus) {
      setTimeout(() => {
        const focusableElements = DomHandler.getFocusableElements(this.host?.nativeElement);
        if (focusableElements.length === 0) {
          this.host.nativeElement.focus();
        }
        if (focusableElements.length > 0) {
          focusableElements[0].focus();
        }
        this.focused = true;
      });
    }
  }
  static ɵfac = /*@__PURE__*/(() => {
    let ɵAutoFocus_BaseFactory;
    return function AutoFocus_Factory(__ngFactoryType__) {
      return (ɵAutoFocus_BaseFactory || (ɵAutoFocus_BaseFactory = i0.ɵɵgetInheritedFactory(AutoFocus)))(__ngFactoryType__ || AutoFocus);
    };
  })();
  static ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
    type: AutoFocus,
    selectors: [["", "pAutoFocus", ""]],
    inputs: {
      autofocus: [2, "autofocus", "autofocus", booleanAttribute],
      _autofocus: [0, "pAutoFocus", "_autofocus"]
    },
    features: [i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoFocus, [{
    type: Directive,
    args: [{
      selector: '[pAutoFocus]',
      standalone: true
    }]
  }], null, {
    autofocus: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    _autofocus: [{
      type: Input,
      args: ['pAutoFocus']
    }]
  });
})();
class AutoFocusModule {
  static ɵfac = function AutoFocusModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AutoFocusModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: AutoFocusModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AutoFocusModule, [{
    type: NgModule,
    args: [{
      imports: [AutoFocus],
      exports: [AutoFocus]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AutoFocusModule, {
    imports: [AutoFocus],
    exports: [AutoFocus]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AutoFocus, AutoFocusModule };
