import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import { HttpContextToken, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { tap, finalize } from 'rxjs/operators';
import * as i1 from '@ngx-loading-bar/core';
import { LoadingBarModule } from '@ngx-loading-bar/core';
const NGX_LOADING_BAR_IGNORED = new HttpContextToken(() => false);
class LoadingBarInterceptor {
  constructor(loader) {
    this.loader = loader;
  }
  intercept(req, next) {
    if (req.context.get(NGX_LOADING_BAR_IGNORED) === true) {
      return next.handle(req);
    }
    let started = false;
    const ref = this.loader.useRef('http');
    return next.handle(req).pipe(tap(() => {
      if (!started) {
        ref.start();
        started = true;
      }
    }), finalize(() => started && ref.complete()));
  }
  static {
    this.ɵfac = function LoadingBarInterceptor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoadingBarInterceptor)(i0.ɵɵinject(i1.LoadingBarService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LoadingBarInterceptor,
      factory: LoadingBarInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingBarInterceptor, [{
    type: Injectable
  }], function () {
    return [{
      type: i1.LoadingBarService
    }];
  }, null);
})();
function provideLoadingBarInterceptor() {
  return {
    provide: HTTP_INTERCEPTORS,
    useClass: LoadingBarInterceptor,
    multi: true
  };
}
class LoadingBarHttpClientModule {
  static {
    this.ɵfac = function LoadingBarHttpClientModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LoadingBarHttpClientModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: LoadingBarHttpClientModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingBarInterceptor,
        multi: true
      }],
      imports: [HttpClientModule, LoadingBarModule, HttpClientModule, LoadingBarModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingBarHttpClientModule, [{
    type: NgModule,
    args: [{
      imports: [HttpClientModule, LoadingBarModule],
      exports: [HttpClientModule, LoadingBarModule],
      providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: LoadingBarInterceptor,
        multi: true
      }]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { LoadingBarHttpClientModule, NGX_LOADING_BAR_IGNORED, provideLoadingBarInterceptor };
