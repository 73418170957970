import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { CardModule } from 'primeng/card';
import { Table, TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { OrganizationService } from '../../services/organizations.service';
import {
  Organization,
  OrganizationItemsPaginated,
  OrganizationCreateDto,
} from '../../models/organization';
import { MessageService } from 'primeng/api';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-pages-organizations',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    DividerModule,
    CardModule,
    TableModule,
    ProgressBarModule,
    ToastModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    FloatLabelModule,
    InputMaskModule,
    DialogModule
  ],
  providers: [MessageService],
  styleUrls: [],
  templateUrl: './organizations.component.html',
})
export class OrganizationsComponent implements OnInit {

  // ====> Injects
  private readonly organizationsService: OrganizationService =
    inject(OrganizationService);
  private readonly messageService: MessageService = inject(MessageService);

  // ====> Variables
  public organizations: Organization[] = [];
  public pageSize: number = 10;
  public loading: boolean = false;
  public searchValue: string | undefined;
  public selectedOrganization: Organization | undefined;

  // Variables para el modal
  public displayDialog: boolean = false;
  public newOrganization: OrganizationCreateDto = {
    name: '',
    nif: '',
    invoiceAddress: '',
    contactEmail: '',
    contactPhone: '',
    contactName: '',
    licenseType: '',
  };

  // Referencia a la tabla para aplicar los filtros desde el HTML
  @ViewChild('dt') dt: Table | undefined;

  // ====> Life Cycle
  ngOnInit() {
    this.getOrganizations(1, 1000);
  }

  // ====> Methods

  /**
   * Obtiene la lista de organizaciones.
   * @param page Número de página actual.
   * @param pageSize Tamaño de página.
   */
  private async getOrganizations(
    page: number,
    pageSize: number
  ): Promise<void> {
    this.loading = true;
    try {
      const itemsPaginated: OrganizationItemsPaginated =
        await this.organizationsService.getOrganizations(page, pageSize);
      this.organizations = itemsPaginated.items;
      console.log(this.organizations);
    } catch (error: any) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error al cargar organizaciones',
        detail: error.message || 'Error desconocido',
        life: 3000,
      });
      console.error('Error al cargar organizaciones:', error);
    } finally {
      this.loading = false;
    }
  }

  /**
   * Aplica el filtro a la tabla.
   * @param event Evento del input.
   * @param field Campo de la organización a filtrar.
   * @param matchMode Modo de comparación (por ejemplo, 'contains').
   */
  applyFilter(event: Event, field: string, matchMode: string): void {
    const value = (event.target as HTMLInputElement).value;
    this.dt?.filter(value, field, matchMode);
  }

  clear(table: Table) {
    table.clear();
    this.searchValue = '';
  }

  /**
   * Abre el modal para crear una nueva organización.
   */
  openNewOrganizationModal(): void {
    this.newOrganization = {
      name: '',
      nif: '',
      invoiceAddress: '',
      contactEmail: '',
      contactPhone: '',
      contactName: '',
      licenseType: ''
    };
    this.displayDialog = true;
  }

  /**
   * Guarda la nueva organización mediante el servicio y actualiza la lista.
   */
  async saveNewOrganization(): Promise<void> {
    this.loading = true;
    try {
      const created = await this.organizationsService.createOrganization(this.newOrganization);
      // Actualiza la lista (puedes también recargar todas las organizaciones)
      await this.getOrganizations(1, 1000);
      this.messageService.add({
        severity: 'success',
        summary: 'Organización creada',
        detail: `La organización ${created.name} ha sido creada exitosamente.`,
        life: 3000,
      });
      this.displayDialog = false;
    } catch (error: any) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error al crear organización',
        detail: error.message || 'Error desconocido',
        life: 3000,
      });
    } finally {
        this.loading = false;
    }
  }

  /**
   * Evento al seleccionar una fila de la tabla (organización).
   * @param event Evento de selección.
   */
  onOrganizationSelect(event: any): void {
    console.log('Organización seleccionada:', event.data);
    // Aquí puedes manejar la selección de la organización
  }
}
