import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, RouterOutlet } from '@angular/router';
import { NgxLoadingBar } from '@ngx-loading-bar/core';
import { LayoutService } from './services/layout.service';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [CommonModule, RouterOutlet, RouterModule, NgxLoadingBar],
    template: `
        <router-outlet></router-outlet>
        <ngx-loading-bar [includeSpinner]="false" height="3px" [color]="'#4EC6CE'"></ngx-loading-bar>
    `,
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

    constructor(private layoutService: LayoutService) {}

    ngOnInit(): void {
        const theme = localStorage.getItem('theme');
        if (theme) {
            this.toggleDarkMode(theme === 'dark');
        } else {
            this.toggleDarkMode(false);
            localStorage.setItem('theme', 'light');
        }
    }

    toggleDarkMode(dark: boolean): void {
        this.layoutService.layoutConfig.update((state) => ({
            ...state,
            darkTheme: dark,
        }));
    }

}