import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, Component, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding, NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const classes = {
  root: 'p-inputicon'
};
class InputIconStyle extends BaseStyle {
  name = 'inputicon';
  classes = classes;
  static ɵfac = /*@__PURE__*/(() => {
    let ɵInputIconStyle_BaseFactory;
    return function InputIconStyle_Factory(__ngFactoryType__) {
      return (ɵInputIconStyle_BaseFactory || (ɵInputIconStyle_BaseFactory = i0.ɵɵgetInheritedFactory(InputIconStyle)))(__ngFactoryType__ || InputIconStyle);
    };
  })();
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: InputIconStyle,
    factory: InputIconStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputIconStyle, [{
    type: Injectable
  }], null, null);
})();
const _c0 = ["*"];
/**
 * InputIcon displays an icon.
 * @group Components
 */
class InputIcon extends BaseComponent {
  /**
   * Style class of the element.
   * @group Props
   */
  styleClass;
  get hostClasses() {
    return this.styleClass;
  }
  _componentStyle = inject(InputIconStyle);
  static ɵfac = /*@__PURE__*/(() => {
    let ɵInputIcon_BaseFactory;
    return function InputIcon_Factory(__ngFactoryType__) {
      return (ɵInputIcon_BaseFactory || (ɵInputIcon_BaseFactory = i0.ɵɵgetInheritedFactory(InputIcon)))(__ngFactoryType__ || InputIcon);
    };
  })();
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: InputIcon,
    selectors: [["p-inputicon"], ["p-inputIcon"]],
    hostVars: 4,
    hostBindings: function InputIcon_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.hostClasses);
        i0.ɵɵclassProp("p-inputicon", true);
      }
    },
    inputs: {
      styleClass: "styleClass"
    },
    features: [i0.ɵɵProvidersFeature([InputIconStyle]), i0.ɵɵInheritDefinitionFeature],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function InputIcon_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    dependencies: [CommonModule, SharedModule],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputIcon, [{
    type: Component,
    args: [{
      selector: 'p-inputicon, p-inputIcon',
      standalone: true,
      imports: [CommonModule, SharedModule],
      template: `<ng-content></ng-content>`,
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [InputIconStyle],
      host: {
        '[class]': 'styleClass',
        '[class.p-inputicon]': 'true'
      }
    }]
  }], null, {
    styleClass: [{
      type: Input
    }],
    hostClasses: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(InputIcon, {
    className: "InputIcon",
    filePath: "inputicon.ts",
    lineNumber: 24
  });
})();
class InputIconModule {
  static ɵfac = function InputIconModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || InputIconModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: InputIconModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
    imports: [InputIcon, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputIconModule, [{
    type: NgModule,
    args: [{
      imports: [InputIcon, SharedModule],
      exports: [InputIcon, SharedModule]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(InputIconModule, {
    imports: [InputIcon, SharedModule],
    exports: [InputIcon, SharedModule]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { InputIcon, InputIconModule, InputIconStyle };
