/**
 * Angular
 */
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideRouter } from '@angular/router';

/**
 * Librerías de terceros
 */
import {
    LoadingBarHttpClientModule,
    provideLoadingBarInterceptor,
} from '@ngx-loading-bar/http-client';

import { providePrimeNG } from 'primeng/config';
import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';

/**
 * Keycloak
 */
import { provideKeycloak } from 'keycloak-angular';

/**
 * Environment
 */
import { environment } from '../environments/environment';

/**
 * Rutas
 */
import { routes } from './app.routes';

export const TNPTheme = definePreset(Aura, {
    semantic: {
        primary: {
            50: '{blue.50}',
            100: '{blue.100}',
            200: '{blue.200}',
            300: '{blue.300}',
            400: '{blue.400}',
            500: '{blue.500}',
            600: '{blue.600}',
            700: '{blue.700}',
            800: '{blue.800}',
            900: '{blue.900}',
            950: '{blue.950}'
        }
    }
});

export const appConfig: ApplicationConfig = {
    providers: [
        // Configuración de Angular
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideAnimationsAsync(),
        provideHttpClient(withFetch()),
        provideRouter(routes),

        // Configuración de librerías de terceros
        importProvidersFrom(LoadingBarHttpClientModule),
        provideLoadingBarInterceptor(),
        providePrimeNG({
            theme: {
                preset: TNPTheme,
                options: { darkModeSelector: '.app-dark' }
            },
            ripple: true,
            inputVariant: 'outlined',
        }),

        // Configuración de Keycloak usando variables de entorno
        provideKeycloak({
            config: {
                url: environment.keycloak.url,
                realm: environment.keycloak.realm,
                clientId: environment.keycloak.clientId,
            },
            initOptions: {
                onLoad: 'login-required',
                checkLoginIframe: false,
                redirectUri: window.location.origin + '/',
                scope: environment.keycloak.scope,
            },
        }),
    ],
};
