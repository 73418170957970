import { Component, inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { CardModule } from 'primeng/card';
import { OrganizationService } from '../../../services/organizations.service';
import { Organization } from '../../../models/organization';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { ProgressBarModule } from 'primeng/progressbar';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-pages-organization',
    standalone: true,
    imports: [CommonModule, ButtonModule, DividerModule, CardModule, ToastModule, ProgressBarModule],
    providers: [MessageService],
    styleUrls: [],
    templateUrl: './organization.component.html',
})
export class OrganizationComponent implements OnInit {

    // ====> Injects
    private readonly router: Router = inject(Router);
    private readonly route: ActivatedRoute = inject(ActivatedRoute);
    private readonly organizationService: OrganizationService = inject(OrganizationService);

    // ====> Variables
    public loading: boolean = true;
    public organizationId: string = '';
    public organization: Organization | null = null;

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.organizationId = params['organizationId'];
            this.organizationService.getOrganizationById(this.organizationId).then((organization: Organization) => {
                this.organization = organization;
                console.log('Organization:', this.organization);
            }).catch((error) => {
                console.error('Error fetching organization:', error);
                this.router.navigate(['/organizations']);
            }).finally(() => {
                this.loading = false;
            });
        });
    }
}
