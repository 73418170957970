import { CommonModule } from '@angular/common';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    NavigationEnd,
    Router,
    RouterModule,
    RouterOutlet,
} from '@angular/router';
import { StyleClassModule } from 'primeng/styleclass';
import { FooterComponent } from '../components/footer/footer.component';
import {
    TopbarActions,
    TopbarComponent,
} from '../components/topbar/topbar.component';
import { filter, Subscription } from 'rxjs';
import { LayoutService } from '../../services/layout.service';
import { topbarItems } from './topbar.items';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-topbarLayout',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        RouterOutlet,
        TopbarComponent,
        FooterComponent,
        StyleClassModule,
        BreadcrumbModule,
    ],
    template: `
        <div class="layout-wrapper">
            <app-topbar [actions]="topbarActions" [showSidebarButton]="false" />
            <div class="layout-main-container">
                <p-breadcrumb
                    class="max-w-full bg-none"
                    [model]="breadcrumbs"
                    [home]="home"
                >
                    <ng-template #item let-item>
                        <ng-container *ngIf="item.route; else elseBlock">
                            <a
                                [routerLink]="item.route"
                                class="p-breadcrumb-item-link"
                            >
                                <span
                                    [ngClass]="[
                                        item.icon ? item.icon : '',
                                        'text-color'
                                    ]"
                                ></span>
                                <span class="text-primary font-semibold">{{
                                    item.label
                                }}</span>
                            </a>
                        </ng-container>
                        <ng-template #elseBlock>
                            <a [href]="item.url">
                                <span class="text-color">{{ item.label }}</span>
                            </a>
                        </ng-template>
                    </ng-template>
                </p-breadcrumb>
                <div class="layout-main">
                    <router-outlet></router-outlet>
                </div>
                <footer-cmp />
            </div>
            <div class="layout-mask animate-fadein"></div>
        </div>
    `,
    styleUrls: [],
})
export class TopbarLayoutComponent implements OnInit {
    overlayMenuOpenSubscription: Subscription | undefined;

    menuOutsideClickListener: any;

    topbarActions: TopbarActions[] = topbarItems;
    breadcrumbs: MenuItem[] | undefined;
    home: MenuItem = { icon: 'pi pi-home', routerLink: '/' };

    @ViewChild(TopbarComponent) appTopBar!: TopbarComponent;

    constructor(
        public layoutService: LayoutService,
        public renderer: Renderer2,
        public router: Router
    ) {}

    ngOnInit(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.breadcrumbs = this.createBreadcrumbs();
            });
    }

    private createBreadcrumbs(): MenuItem[] {
        let route = this.router.routerState.snapshot.root;
        let breadcrumbs: MenuItem[] = [];

        while (route.firstChild) {
            route = route.firstChild;
        }

        const routeBreadcrumbs =
            (route.data['breadcrumbs'] as Array<{
                label: string;
                url: string;
            }>) || [];

        breadcrumbs = routeBreadcrumbs.map((bc) => ({
            label: this.replaceParams(bc.label, route.params),
            routerLink: this.replaceParams(bc.url, route.params),
        }));

        return breadcrumbs;
    }

    private replaceParams(
        str: string,
        params: { [key: string]: string }
    ): string {
        let result = str;
        for (const key in params) {
            result = result.replace(`:${key}`, params[key]);
        }
        return result;
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(
                new RegExp(
                    '(^|\\b)' +
                        'blocked-scroll'.split(' ').join('|') +
                        '(\\b|$)',
                    'gi'
                ),
                ' '
            );
        }
    }

    ngOnDestroy() {
        if (this.overlayMenuOpenSubscription) {
            this.overlayMenuOpenSubscription.unsubscribe();
        }

        if (this.menuOutsideClickListener) {
            this.menuOutsideClickListener();
        }
    }
}
