import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
    selector: 'footer-cmp',
    imports: [RouterModule],
    template: `
        <div class="layout-footer">
        Developed by
        <a href="https://thenextpangea.com" target="_blank" rel="noopener noreferrer" class="text-primary font-bold hover:underline">TheNextPangea S.L.</a>
    </div>
    `
})
export class FooterComponent {
    constructor(public router: Router) {}
}
