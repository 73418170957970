@if (loading) {
<p-progressbar mode="indeterminate" [style]="{ height: '6px' }" />
}
<p-toast />

@if (!loading) {
<p-card>
    <ng-template #header>
        <div class="flex justify-between items-center m-5">
            <h2><span class="pi pi-building m-5" style="font-size: 1.5rem"></span>Organizaciones</h2>
            <div>
                @if (authService.getUserRole() === "SuperAdmin" || authService.getUserRole() === "Admin") {
                    <p-button
                        class="mr-2"
                        label="Agregar Organización"
                        icon="pi pi-plus"
                        (click)="openNewOrganizationModal(false)"
                    ></p-button>
                }
                @if (authService.getUserRole() === "SuperAdmin") {
                    <p-button
                        severity="success"
                        label="Agregar Organización Partner"
                        icon="pi pi-plus"
                        (click)="openNewOrganizationModal(true)"
                    ></p-button>
                }
            </div>
        </div>
    </ng-template>
    @if (organizations.length === 0) {
    <div class="flex justify-center items-center text-center">
        <p-message
            severity="info"
            size="large"
            icon="pi pi-inbox"
            text="No hay organizaciones disponibles."
        ></p-message>
    </div>
    } @else {

    <p-table
        #dt
        [value]="organizations"
        [paginator]="true"
        [rows]="pageSize"
        [totalRecords]="organizations.length"
        [rowsPerPageOptions]="[10, 20, 50, 100]"
        [loading]="loading"
        [size]="'large'"
        selectionMode="single"
        [(selection)]="selectedOrganization"
        stripedRows
        (onRowSelect)="onOrganizationSelect($event)"
        [globalFilterFields]="[
            'organizationType',
            'nif',
            'name',
            'invoiceAddress',
            'contactEmail',
            'contactPhone',
            'contactName',
            'licenseType',
            'parentOrganization'
        ]"
    >
        <ng-template #caption>
            <div class="flex">
                <p-button
                    label="Clear"
                    [outlined]="true"
                    icon="pi pi-filter-slash"
                    (click)="clear(dt)"
                />
                <p-iconfield iconPosition="left" class="ml-auto">
                    <input
                        pInputText
                        type="text"
                        class="w-full"
                        (input)="applyFilter($event, 'name', 'contains')"
                        placeholder="Buscar..."
                    />
                    <p-inputicon class="pi pi-search" />
                </p-iconfield>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th
                    pSortableColumn="organizationType"
                    style="min-width: 14rem"
                    scope="col"
                >
                    <div class="flex justify-between items-center">
                        Tipo de Organización
                        <p-sortIcon field="organizationType" />
                        <p-columnFilter
                            type="text"
                            field="organizationType"
                            display="menu"
                            class="ml-auto"
                        />
                    </div>
                </th>
                <th pSortableColumn="nif" style="min-width: 14rem" scope="col">
                    <div class="flex justify-between items-center">
                        NIF
                        <p-sortIcon field="nif" />
                        <p-columnFilter
                            type="text"
                            field="nif"
                            display="menu"
                            class="ml-auto"
                        />
                    </div>
                </th>
                <th pSortableColumn="name" style="min-width: 14rem" scope="col">
                    <div class="flex justify-between items-center">
                        Nombre
                        <p-sortIcon field="name" />
                        <p-columnFilter
                            type="text"
                            field="name"
                            display="menu"
                            class="ml-auto"
                        />
                    </div>
                </th>
                <th
                    pSortableColumn="invoiceAddress"
                    style="min-width: 14rem"
                    scope="col"
                >
                    <div class="flex justify-between items-center">
                        Dirección de Facturación
                        <p-sortIcon field="invoiceAddress" />
                        <p-columnFilter
                            type="text"
                            field="invoiceAddress"
                            display="menu"
                            class="ml-auto"
                        />
                    </div>
                </th>
                <th
                    pSortableColumn="contactEmail"
                    style="min-width: 14rem"
                    scope="col"
                >
                    Email de Contacto
                    <p-sortIcon field="contactEmail"></p-sortIcon>
                </th>
                <th
                    pSortableColumn="contactPhone"
                    style="min-width: 14rem"
                    scope="col"
                >
                    Teléfono
                    <p-sortIcon field="contactPhone"></p-sortIcon>
                </th>
                <th
                    pSortableColumn="contactName"
                    style="min-width: 14rem"
                    scope="col"
                >
                    Contacto
                    <p-sortIcon field="contactName"></p-sortIcon>
                </th>
                <th
                    pSortableColumn="licenseType"
                    style="min-width: 14rem"
                    scope="col"
                >
                    Tipo de Licencia
                    <p-sortIcon field="licenseType"></p-sortIcon>
                </th>

                <th scope="col" style="min-width: 14rem">Metadata</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-organization>
            <tr [pSelectableRow]="organization">
                <td>
                    <p-tag
                        [value]="organization.organizationType"
                        [severity]="
                            organization.organizationType === 'Partner'
                                ? 'success'
                                : 'info'
                        "
                    ></p-tag>
                </td>
                <td>{{ organization.nif }}</td>
                <td>{{ organization.name }}</td>

                <td>{{ organization.invoiceAddress }}</td>
                <td>{{ organization.contactEmail }}</td>
                <td>{{ organization.contactPhone }}</td>
                <td>{{ organization.contactName }}</td>
                <td>{{ organization.licenseType }}</td>
                <td>{{ organization.metadata | json }}</td>
            </tr>
        </ng-template>
    </p-table>
    }
</p-card>
}

<!-- Modal para agregar organización -->
<p-dialog
    header="Agregar Organización"
    [(visible)]="displayDialog"
    [modal]="true"
    [closable]="true"
    [style]="{ width: '30vw' }"
>
    <div class="p-fluid">
        <div class="p-field mt-10">
            <p-floatLabel>
                <input
                    id="name"
                    pInputText
                    type="text"
                    [(ngModel)]="newOrganization.name"
                    class="w-full"
                />
                <label for="name">Nombre</label>
            </p-floatLabel>
        </div>
        <div class="p-field mt-10">
            <p-floatLabel>
                <input
                    id="nif"
                    pInputText
                    type="text"
                    [(ngModel)]="newOrganization.nif"
                    class="w-full"
                />
                <label for="nif">NIF</label>
            </p-floatLabel>
        </div>
        <div class="p-field mt-10">
            <p-floatLabel>
                <input
                    id="invoiceAddress"
                    pInputText
                    type="text"
                    [(ngModel)]="newOrganization.invoiceAddress"
                    class="w-full"
                />
                <label for="invoiceAddress">Dirección de Facturación</label>
            </p-floatLabel>
        </div>
        <div class="p-field mt-10">
            <p-floatLabel>
                <input
                    id="contactEmail"
                    pInputText
                    type="text"
                    [(ngModel)]="newOrganization.contactEmail"
                    class="w-full"
                />
                <label for="contactEmail">Email de Contacto</label>
            </p-floatLabel>
        </div>
        <div class="p-field mt-10">
            <p-floatLabel class="w-full">
                <p-inputMask
                    id="contactPhone"
                    mask="(+99)999999999"
                    [(ngModel)]="newOrganization.contactPhone"
                    class="w-full"
                ></p-inputMask>
                <label for="contactPhone">Teléfono</label>
            </p-floatLabel>
        </div>
        <div class="p-field mt-10">
            <p-floatLabel>
                <input
                    id="contactName"
                    pInputText
                    type="text"
                    [(ngModel)]="newOrganization.contactName"
                    class="w-full"
                />
                <label for="contactName">Contacto</label>
            </p-floatLabel>
        </div>
        <div class="p-field mt-10">
            <p-floatLabel>
                <input
                    id="licenseType"
                    pInputText
                    type="text"
                    [(ngModel)]="newOrganization.licenseType"
                    class="w-full"
                />
                <label for="licenseType">Tipo de Licencia</label>
            </p-floatLabel>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <p-button
            label="Cancelar"
            [outlined]="true"
            icon="pi pi-times"
            severity="secondary"
            (click)="displayDialog = false"
        ></p-button>
        <p-button
            label="Guardar"
            icon="pi pi-check"
            [disabled]="
                !newOrganization.name ||
                !newOrganization.nif ||
                !newOrganization.invoiceAddress ||
                !newOrganization.contactEmail ||
                !newOrganization.contactPhone ||
                !newOrganization.contactName ||
                !newOrganization.licenseType
            "
            (click)="saveNewOrganization()"
        ></p-button>
    </ng-template>
</p-dialog>
