import { CommonModule } from '@angular/common';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    NavigationEnd,
    Router,
    RouterModule,
    RouterOutlet,
} from '@angular/router';
import { StyleClassModule } from 'primeng/styleclass';
import { FooterComponent } from '../components/footer/footer.component';
import {
    TopbarActions,
    TopbarComponent,
} from '../components/topbar/topbar.component';
import { filter, Subscription } from 'rxjs';
import { LayoutService } from '../../services/layout.service';
import { topbarItems } from './topbar.items';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-topbarLayout',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        RouterOutlet,
        TopbarComponent,
        FooterComponent,
        StyleClassModule,
        BreadcrumbModule,
    ],
    template: `
        <div class="layout-wrapper">
            <app-topbar [actions]="topbarActions" [showSidebarButton]="false" />
            <div class="layout-main-container">
                <p-breadcrumb
                    class="max-w-full bg-none"
                    [model]="breadcrumbs"
                    [home]="home"
                >
                    <ng-template #item let-item>
                        <ng-container *ngIf="item.route; else elseBlock">
                            <a
                                [routerLink]="item.route"
                                class="p-breadcrumb-item-link"
                            >
                                <span
                                    [ngClass]="[
                                        item.icon ? item.icon : '',
                                        'text-color'
                                    ]"
                                ></span>
                                <span class="text-primary font-semibold">{{
                                    item.label
                                }}</span>
                            </a>
                        </ng-container>
                        <ng-template #elseBlock>
                            <a [href]="item.url">
                                <span class="text-color">{{ item.label }}</span>
                            </a>
                        </ng-template>
                    </ng-template>
                </p-breadcrumb>
                <div class="layout-main">
                    <router-outlet></router-outlet>
                </div>
                <footer-cmp />
            </div>
            <div class="layout-mask animate-fadein"></div>
        </div>
    `,
    styleUrls: [],
})
export class TopbarLayoutComponent implements OnInit {
    overlayMenuOpenSubscription: Subscription | undefined;

    menuOutsideClickListener: any;

    topbarActions: TopbarActions[] = topbarItems;
    breadcrumbs: MenuItem[] | undefined;
    home: MenuItem = { icon: 'pi pi-home', routerLink: '/' };

    @ViewChild(TopbarComponent) appTopBar!: TopbarComponent;

    constructor(
        public layoutService: LayoutService,
        public renderer: Renderer2,
        public router: Router
    ) {}

    ngOnInit(): void {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                this.breadcrumbs = this.createBreadcrumbs(
                    this.router.routerState.snapshot.root
                );
            });
    }

    private createBreadcrumbs(
        route: ActivatedRouteSnapshot,
        url: string = '',
        breadcrumbs: MenuItem[] = []
    ): MenuItem[] {
        // Si no hay rutas hijas, devolvemos lo acumulado
        if (!route.children || route.children.length === 0) {
            return breadcrumbs;
        }

        for (const child of route.children) {
            // Obtenemos la parte de la URL de la ruta
            const routeURL: string = child.url
                .map((segment) => segment.path)
                .join('/');
            // Acumulamos la URL si existe
            if (routeURL) {
                url += `/${routeURL}`;
            }

            // Usamos el dato 'breadcrumb' definido en la configuración de rutas
            if (child.data?.['breadcrumb']) {
                const label = child.data['breadcrumb'];
                if (label) {
                    breadcrumbs.push({ label, routerLink: url });
                }
            }

            // Llamada recursiva para rutas hijas
            return this.createBreadcrumbs(child, url, breadcrumbs);
        }
        return breadcrumbs;
    }

    blockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.add('blocked-scroll');
        } else {
            document.body.className += ' blocked-scroll';
        }
    }

    unblockBodyScroll(): void {
        if (document.body.classList) {
            document.body.classList.remove('blocked-scroll');
        } else {
            document.body.className = document.body.className.replace(
                new RegExp(
                    '(^|\\b)' +
                        'blocked-scroll'.split(' ').join('|') +
                        '(\\b|$)',
                    'gi'
                ),
                ' '
            );
        }
    }

    ngOnDestroy() {
        if (this.overlayMenuOpenSubscription) {
            this.overlayMenuOpenSubscription.unsubscribe();
        }

        if (this.menuOutsideClickListener) {
            this.menuOutsideClickListener();
        }
    }
}
