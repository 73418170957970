const currentUrlBase = window.location.host.split(':')[0];

export const environment = {
    production: true,
    baseUrl: `https://${currentUrlBase}:5001`,
    customerManager: {
      url: '',
      realm: '',
      clientId: 'frontend',
      clientSecret: ''
    },
    keycloak: {
      url: `https://${currentUrlBase}/api/auth`,
      realm: 'Auth-Realm',
      clientId: 'frontend',
      scope: 'frontend-scope',
  },
  };
  