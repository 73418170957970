import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { CardModule } from 'primeng/card';

@Component({
    selector: 'app-pages-users',
    standalone: true,
    imports: [CommonModule, ButtonModule, DividerModule, CardModule],
    styleUrls: [],
    templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
    ngOnInit() {}
}
