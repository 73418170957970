import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import {
    Organization,
    OrganizationCreateDto,
    OrganizationItemsPaginated,
} from '../models/organization';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
    private readonly http = inject(HttpClient);
    private readonly authService = inject(AuthService);
    private readonly baseUrl = environment.baseUrl;

    // Método que obtiene las cabeceras incluyendo el token
    private async getHeaders(): Promise<HttpHeaders> {
        const token = await this.authService.getToken();
        return new HttpHeaders({
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json; x-api-version=2.0',
        });
    }

    // Obtiene todas las organizaciones con paginación y filtros opcionales.
    async getOrganizations(
        page: number = 1,
        pageSize: number = 100,
        filters?: any
    ): Promise<OrganizationItemsPaginated> {
        let url = `${this.baseUrl}/api/v2/Organizations?page=${page}&pageSize=${pageSize}`;
        if (filters) {
            url += `&filters=${encodeURIComponent(JSON.stringify(filters))}`;
        }
        const headers = await this.getHeaders();
        return await firstValueFrom(
            this.http.get<OrganizationItemsPaginated>(url, { headers })
        );
    }

    // Crea una nueva organización.
    async createOrganization(
        organization: OrganizationCreateDto
    ): Promise<Organization> {
        const headers = await this.getHeaders();
        return await firstValueFrom(
            this.http.post<Organization>(
                `${this.baseUrl}/api/v2/Organizations`,
                organization,
                { headers }
            )
        );
    }

    // Actualiza una organización existente.
    async updateOrganization(organization: Organization): Promise<void> {
        const headers = await this.getHeaders();
        await firstValueFrom(
            this.http.put(
                `${this.baseUrl}/api/v2/Organizations`,
                organization,
                { headers }
            )
        );
    }

    // Obtiene una organización por su ID.
    async getOrganizationById(id: string): Promise<Organization> {
        const headers = await this.getHeaders();
        return await firstValueFrom(
            this.http.get<Organization>(
                `${this.baseUrl}/api/v2/Organizations/${id}`,
                { headers }
            )
        );
    }

    // Elimina una organización por su ID.
    async deleteOrganization(id: string): Promise<void> {
        const headers = await this.getHeaders();
        await firstValueFrom(
            this.http.delete(`${this.baseUrl}/api/v2/Organizations/${id}`, {
                headers,
            })
        );
    }

    // Crea una nueva organización hija utilizando el ID de la organización padre.
    async createChildOrganization(
        parentOrganizationId: string,
        organization: OrganizationCreateDto
    ): Promise<Organization> {
        const headers = await this.getHeaders();
        return await firstValueFrom(
            this.http.post<Organization>(
                `${this.baseUrl}/api/v2/Organizations/${parentOrganizationId}`,
                organization,
                { headers }
            )
        );
    }

    // Crea un nuevo distribuidor (partner).
    async createPartnerOrganization(
        organization: OrganizationCreateDto
    ): Promise<Organization> {
        const headers = await this.getHeaders();
        return await firstValueFrom(
            this.http.post<Organization>(
                `${this.baseUrl}/api/v2/Organizations/partner`,
                organization,
                { headers }
            )
        );
    }
}
