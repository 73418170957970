import { isPlatformBrowser } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, NgZone, effect, Directive, NgModule } from '@angular/core';
import { removeClass, getHeight, getWidth, getOuterWidth, getOuterHeight, getOffset, addClass, remove } from '@primeuix/utils';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
/* For PrimeNG */
.p-ripple {
    overflow: hidden;
    position: relative;
}

.p-ink {
    display: block;
    position: absolute;
    background: ${dt('ripple.background')};
    border-radius: 100%;
    transform: scale(0);
}

.p-ink-active {
    animation: ripple 0.4s linear;
}

.p-ripple-disabled .p-ink {
    display: none !important;
}

@keyframes ripple {
    100% {
        opacity: 0;
        transform: scale(2.5);
    }
}
`;
const classes = {
  root: 'p-ink'
};
class RippleStyle extends BaseStyle {
  name = 'ripple';
  theme = theme;
  classes = classes;
  static ɵfac = /*@__PURE__*/(() => {
    let ɵRippleStyle_BaseFactory;
    return function RippleStyle_Factory(__ngFactoryType__) {
      return (ɵRippleStyle_BaseFactory || (ɵRippleStyle_BaseFactory = i0.ɵɵgetInheritedFactory(RippleStyle)))(__ngFactoryType__ || RippleStyle);
    };
  })();
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: RippleStyle,
    factory: RippleStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RippleStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * Ripple directive adds ripple effect to the host element.
 *
 * [Live Demo](https://www.primeng.org/ripple)
 *
 * @module ripplestyle
 *
 */
var RippleClasses;
(function (RippleClasses) {
  /**
   * Class name of the root element
   */
  RippleClasses["root"] = "p-ink";
})(RippleClasses || (RippleClasses = {}));

/**
 * Ripple directive adds ripple effect to the host element.
 * @group Components
 */
class Ripple extends BaseComponent {
  zone = inject(NgZone);
  _componentStyle = inject(RippleStyle);
  animationListener;
  mouseDownListener;
  timeout;
  constructor() {
    super();
    effect(() => {
      if (isPlatformBrowser(this.platformId)) {
        if (this.config.ripple()) {
          this.zone.runOutsideAngular(() => {
            this.create();
            this.mouseDownListener = this.renderer.listen(this.el.nativeElement, 'mousedown', this.onMouseDown.bind(this));
          });
        } else {
          this.remove();
        }
      }
    });
  }
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  onMouseDown(event) {
    let ink = this.getInk();
    if (!ink || this.document.defaultView?.getComputedStyle(ink, null).display === 'none') {
      return;
    }
    removeClass(ink, 'p-ink-active');
    if (!getHeight(ink) && !getWidth(ink)) {
      let d = Math.max(getOuterWidth(this.el.nativeElement), getOuterHeight(this.el.nativeElement));
      ink.style.height = d + 'px';
      ink.style.width = d + 'px';
    }
    let offset = getOffset(this.el.nativeElement);
    let x = event.pageX - offset.left + this.document.body.scrollTop - getWidth(ink) / 2;
    let y = event.pageY - offset.top + this.document.body.scrollLeft - getHeight(ink) / 2;
    this.renderer.setStyle(ink, 'top', y + 'px');
    this.renderer.setStyle(ink, 'left', x + 'px');
    addClass(ink, 'p-ink-active');
    this.timeout = setTimeout(() => {
      let ink = this.getInk();
      if (ink) {
        removeClass(ink, 'p-ink-active');
      }
    }, 401);
  }
  getInk() {
    const children = this.el.nativeElement.children;
    for (let i = 0; i < children.length; i++) {
      if (typeof children[i].className === 'string' && children[i].className.indexOf('p-ink') !== -1) {
        return children[i];
      }
    }
    return null;
  }
  resetInk() {
    let ink = this.getInk();
    if (ink) {
      removeClass(ink, 'p-ink-active');
    }
  }
  onAnimationEnd(event) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    removeClass(event.currentTarget, 'p-ink-active');
  }
  create() {
    let ink = this.renderer.createElement('span');
    this.renderer.addClass(ink, 'p-ink');
    this.renderer.appendChild(this.el.nativeElement, ink);
    this.renderer.setAttribute(ink, 'aria-hidden', 'true');
    this.renderer.setAttribute(ink, 'role', 'presentation');
    if (!this.animationListener) {
      this.animationListener = this.renderer.listen(ink, 'animationend', this.onAnimationEnd.bind(this));
    }
  }
  remove() {
    let ink = this.getInk();
    if (ink) {
      this.mouseDownListener && this.mouseDownListener();
      this.animationListener && this.animationListener();
      this.mouseDownListener = null;
      this.animationListener = null;
      remove(ink);
    }
  }
  ngOnDestroy() {
    if (this.config && this.config.ripple()) {
      this.remove();
    }
    super.ngOnDestroy();
  }
  static ɵfac = function Ripple_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || Ripple)();
  };
  static ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
    type: Ripple,
    selectors: [["", "pRipple", ""]],
    hostAttrs: [1, "p-ripple"],
    features: [i0.ɵɵProvidersFeature([RippleStyle]), i0.ɵɵInheritDefinitionFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Ripple, [{
    type: Directive,
    args: [{
      selector: '[pRipple]',
      host: {
        class: 'p-ripple'
      },
      standalone: true,
      providers: [RippleStyle]
    }]
  }], () => [], null);
})();
class RippleModule {
  static ɵfac = function RippleModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || RippleModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: RippleModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RippleModule, [{
    type: NgModule,
    args: [{
      imports: [Ripple],
      exports: [Ripple]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(RippleModule, {
    imports: [Ripple],
    exports: [Ripple]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Ripple, RippleClasses, RippleModule, RippleStyle };
