// auth.service.ts
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import Keycloak from 'keycloak-js';
import { UserService } from './user.service';
import { UserInfoDto } from '../models/user';

export type UserRole = 'SuperAdmin' | 'Admin' | 'Regular';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly keycloak = inject<Keycloak>(Keycloak);
    private readonly router = inject(Router);
    private readonly userService: UserService = inject(UserService);

    /**
     * Comprueba si el usuario está autenticado.
     */
    isLoggedIn(): boolean {
        return !!this.keycloak.authenticated;
    }

    /**
     * Obtiene el perfil del usuario.
     */
    getUserProfile() {
        return this.keycloak.loadUserProfile();
    }

    /**
     * Método para iniciar sesión manualmente (si lo necesitas).
     */
    login() {
        this.keycloak.login();
    }

    /**
     * Cierra la sesión del usuario.
     */
    logout() {
        // Remueve el UserInfo del localStorage al cerrar sesión
        if (localStorage.getItem('userInfo')) {
            localStorage.removeItem('userInfo');
        }
        this.keycloak.logout();
    }

    /**
     * Obtiene el token del usuario (útil para llamadas HTTP).
     */
    getToken(): Promise<string | undefined> {
        return Promise.resolve(this.keycloak.token);
    }

    /**
     * Obtiene los grupos desde el token JWT.
     */
    getUserGroups(): string[] {
        const tokenParsed = this.keycloak.tokenParsed as any;
        return tokenParsed?.groups || [];
    }

    /**
     * Determina el rol del usuario basándose en los grupos del token JWT.
     */
    getUserRole(): UserRole {
        const groups = this.getUserGroups();

        if (groups.includes('SuperAdmins')) {
            return 'SuperAdmin';
        }

        if (groups.includes('Admins')) {
            return 'Admin';
        }

        // Si no tiene los grupos anteriores, es usuario Regular
        return 'Regular';
    }

    /**
     * Obtiene el usuario actual.
     */
    async getCurrentUser(): Promise<UserInfoDto> {
        const token = await this.getToken();
        if (!token) {
            throw new Error('No token available');
        }
        return await this.userService.getCurrentUser(token);
    }

    /**
     * Guarda el UserInfoDto en el localStorage.
     *
     * @param userInfo - El objeto UserInfoDto a guardar.
     */
    saveUserInfo(userInfo: UserInfoDto): void {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
    }

    /**
     * Compueba si el UserInfoDto ya está guardado en el localStorage.
     */
    isUserInfoSaved(): boolean {
        return localStorage.getItem('userInfo') !== null;
    }

    /**
     * Obtiene el UserInfoDto desde el localStorage o, si no existe, lo obtiene y guarda.
     *
     * @returns Una promesa que se resuelve con el objeto UserInfoDto o null si no existe.
     */
    async getUserInfo(): Promise<UserInfoDto | null> {
        let userInfo = localStorage.getItem('userInfo');
        if (userInfo) {
            return JSON.parse(userInfo);
        } else {
            try {
                const user = await this.getCurrentUser();
                this.saveUserInfo(user);
                return user;
            } catch (error) {
                console.error('Error al obtener el usuario actual', error);
                return null;
            }
        }
    }
}
