import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User, UserInfoDto, UserIam, UserUpdateDto, UserUpdateAdminDto } from '../models/user';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {

  private readonly http = inject(HttpClient);
  private readonly baseUrl = environment.baseUrl;

  private getHeaders(token?: string) {
    return new HttpHeaders({
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json; x-api-version=2.0',
    });
  }

  async getCurrentUser(token: string): Promise<UserInfoDto> {
    const response = await firstValueFrom(
      this.http.get<UserInfoDto>(`${this.baseUrl}/api/v2/Auth`, { headers: this.getHeaders(token) })
    );
    if (!response.user) {
      throw new Error('The "user" property is missing in the response.');
    }
    return response;
  }

  getUsers(organizationId: string, page = 1, pageSize = 100) {
    return this.http.get<User[]>(
      `${this.baseUrl}/api/v2/Organizations/${organizationId}/Users?page=${page}&pageSize=${pageSize}`,
    );
  }

  createUser(organizationId: string, user: UserIam) {
    return this.http.post(
      `${this.baseUrl}/api/v2/Organizations/${organizationId}/Users`,
      user,
    );
  }

  createAdminUser(organizationId: string, user: UserIam) {
    return this.http.post<User>(
      `${this.baseUrl}/api/v2/Organizations/${organizationId}/Users/admin`,
      user,
    );
  }

  updateUser(organizationId: string, userUpdate: UserUpdateDto) {
    return this.http.put(
      `${this.baseUrl}/api/v2/Organizations/${organizationId}/Users`,
      userUpdate,
    );
  }

  updateUserById(organizationId: string, userId: string, userUpdate: UserUpdateAdminDto) {
    return this.http.put(
      `${this.baseUrl}/api/v2/Organizations/${organizationId}/Users/${userId}`,
      userUpdate,
    );
  }

  deleteUser(organizationId: string, userId: string) {
    return this.http.delete(
      `${this.baseUrl}/api/v2/Organizations/${organizationId}/Users/${userId}`,
    );
  }

  sendResetPasswordEmail(organizationId: string, userId: string) {
    return this.http.put(
      `${this.baseUrl}/api/v2/Organizations/${organizationId}/Users/${userId}/reset-password-email`,
      {},
    );
  }
}
