import { Routes } from '@angular/router';
import { InicioComponent } from './pages/inicio/inicio.component';
import { AuthGuard } from './guards/auth.guard';


import { OrganizationsComponent } from './pages/organizations/organizations.component';
import { OrganizationComponent } from './pages/organizations/organization/organization.component';
import { TopbarLayoutComponent } from './layouts/topbarLayout/topbarLayout.component';
import { UsersComponent } from './pages/organizations/organization/users/users.component';

export const routes: Routes = [
    {
        path: '',
        component: TopbarLayoutComponent,
        children: [
            {
                path: '',
                component: InicioComponent,
                data: {
                    breadcrumbs: [
                        { label: 'Inicio', url: '/' }
                    ]
                },
            },
            {
                path: 'organizations',
                component: OrganizationsComponent,
                data: {
                    breadcrumbs: [
                        { label: 'Inicio', url: '/' },
                        { label: 'Organizations', url: '/organizations' }
                    ]
                },
            },
            {
                path: 'organizations/:organizationId',
                component: OrganizationComponent,
                data: {
                    breadcrumbs: [
                        { label: 'Inicio', url: '/' },
                        { label: 'Organizations', url: '/organizations' },
                        { label: ':organizationId', url: '/organizations/:organizationId' }
                    ]
                },
            },
            {
                path: 'organizations/:organizationId/users',
                component: UsersComponent,
                data: {
                    breadcrumbs: [
                        { label: 'Inicio', url: '/' },
                        { label: 'Organizations', url: '/organizations' },
                        { label: ':organizationId', url: '/organizations/:organizationId' },
                        { label: 'Users', url: '/organizations/:organizationId/users' }
                    ]
                },
            },
        ],
        canActivate: [AuthGuard],
    },
];
