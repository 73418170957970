import { DOCUMENT } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, Injectable } from '@angular/core';
import { setAttributes, setAttribute } from '@primeuix/utils';
let _id = 0;
class UseStyle {
  document = inject(DOCUMENT);
  use(css, options = {}) {
    let isLoaded = false;
    let cssRef = css;
    let styleRef = null;
    const {
      immediate = true,
      manual = false,
      name = `style_${++_id}`,
      id = undefined,
      media = undefined,
      nonce = undefined,
      first = false,
      props = {}
    } = options;
    if (!this.document) return;
    styleRef = this.document.querySelector(`style[data-primeng-style-id="${name}"]`) || id && this.document.getElementById(id) || this.document.createElement('style');
    if (!styleRef.isConnected) {
      cssRef = css;
      setAttributes(styleRef, {
        type: 'text/css',
        media,
        nonce
      });
      const HEAD = this.document.head;
      first && HEAD.firstChild ? HEAD.insertBefore(styleRef, HEAD.firstChild) : HEAD.appendChild(styleRef);
      setAttribute(styleRef, 'data-primeng-style-id', name);
    }
    if (styleRef.textContent !== cssRef) {
      styleRef.textContent = cssRef;
    }
    return {
      id,
      name,
      el: styleRef,
      css: cssRef
    };
  }
  static ɵfac = function UseStyle_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || UseStyle)();
  };
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: UseStyle,
    factory: UseStyle.ɵfac,
    providedIn: 'root'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UseStyle, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { UseStyle };
