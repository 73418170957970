import { Routes } from '@angular/router';
import { InicioComponent } from './pages/inicio/inicio.component';
import { AuthGuard } from './guards/auth.guard';


import { OrganizationsComponent } from './pages/organizations/organizations.component';
import { TopbarLayoutComponent } from './layouts/topbarLayout/topbarLayout.component';
import { UsersComponent } from './pages/organizations/users/users.component';

export const routes: Routes = [
    {
        path: '',
        component: TopbarLayoutComponent,
        //component: SidebarLayoutComponent,
        children: [
            { path: '', component: InicioComponent, data: { breadcrumb: 'Inicio' } },
            {
                path: 'organizations',
                component: OrganizationsComponent,
                
                data: { breadcrumb: 'Organizaciones' }
            },
            {
                path: 'organizations/:organizationId/users',
                component: UsersComponent,
                data: { breadcrumb: 'Usuarios' }
            }
        ],
        canActivate: [AuthGuard],
    },
];

