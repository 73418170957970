import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, Directive, Input, input, booleanAttribute, computed, Component, ChangeDetectionStrategy, ViewEncapsulation, NgModule } from '@angular/core';
import { isNotEmpty, isEmpty, uuid, hasClass, removeClass, addClass } from '@primeuix/utils';
import { SharedModule } from 'primeng/api';
import { BaseComponent } from 'primeng/basecomponent';
import { BaseStyle } from 'primeng/base';
const theme = ({
  dt
}) => `
.p-badge {
    display: inline-flex;
    border-radius: ${dt('badge.border.radius')};
    justify-content: center;
    padding: ${dt('badge.padding')};
    background: ${dt('badge.primary.background')};
    color: ${dt('badge.primary.color')};
    font-size: ${dt('badge.font.size')};
    font-weight: ${dt('badge.font.weight')};
    min-width: ${dt('badge.min.width')};
    height: ${dt('badge.height')};
    line-height: ${dt('badge.height')};
}

.p-badge-dot {
    width: ${dt('badge.dot.size')};
    min-width: ${dt('badge.dot.size')};
    height: ${dt('badge.dot.size')};
    border-radius: 50%;
    padding: 0;
}

.p-badge-circle {
    padding: 0;
    border-radius: 50%;
}

.p-badge-secondary {
    background: ${dt('badge.secondary.background')};
    color: ${dt('badge.secondary.color')};
}

.p-badge-success {
    background: ${dt('badge.success.background')};
    color: ${dt('badge.success.color')};
}

.p-badge-info {
    background: ${dt('badge.info.background')};
    color: ${dt('badge.info.color')};
}

.p-badge-warn {
    background: ${dt('badge.warn.background')};
    color: ${dt('badge.warn.color')};
}

.p-badge-danger {
    background: ${dt('badge.danger.background')};
    color: ${dt('badge.danger.color')};
}

.p-badge-contrast {
    background: ${dt('badge.contrast.background')};
    color: ${dt('badge.contrast.color')};
}

.p-badge-sm {
    font-size: ${dt('badge.sm.font.size')};
    min-width: ${dt('badge.sm.min.width')};
    height: ${dt('badge.sm.height')};
    line-height: ${dt('badge.sm.height')};
}

.p-badge-lg {
    font-size: ${dt('badge.lg.font.size')};
    min-width: ${dt('badge.lg.min.width')};
    height: ${dt('badge.lg.height')};
    line-height: ${dt('badge.lg.height')};
}

.p-badge-xl {
    font-size: ${dt('badge.xl.font.size')};
    min-width: ${dt('badge.xl.min.width')};
    height: ${dt('badge.xl.height')};
    line-height: ${dt('badge.xl.height')};
}

/* For PrimeNG (directive)*/

.p-overlay-badge {
    position: relative;
}

.p-overlay-badge > .p-badge {
    position: absolute;
    top: 0;
    inset-inline-end: 0;
    transform: translate(50%, -50%);
    transform-origin: 100% 0;
    margin: 0;
}
`;
const classes = {
  root: ({
    props,
    instance
  }) => ['p-badge p-component', {
    'p-badge-circle': isNotEmpty(props.value) && String(props.value).length === 1,
    'p-badge-dot': isEmpty(props.value) && !instance.$slots.default,
    'p-badge-sm': props.size === 'small',
    'p-badge-lg': props.size === 'large',
    'p-badge-xl': props.size === 'xlarge',
    'p-badge-info': props.severity === 'info',
    'p-badge-success': props.severity === 'success',
    'p-badge-warn': props.severity === 'warn',
    'p-badge-danger': props.severity === 'danger',
    'p-badge-secondary': props.severity === 'secondary',
    'p-badge-contrast': props.severity === 'contrast'
  }]
};
class BadgeStyle extends BaseStyle {
  name = 'badge';
  theme = theme;
  classes = classes;
  static ɵfac = /*@__PURE__*/(() => {
    let ɵBadgeStyle_BaseFactory;
    return function BadgeStyle_Factory(__ngFactoryType__) {
      return (ɵBadgeStyle_BaseFactory || (ɵBadgeStyle_BaseFactory = i0.ɵɵgetInheritedFactory(BadgeStyle)))(__ngFactoryType__ || BadgeStyle);
    };
  })();
  static ɵprov = /*@__PURE__*/i0.ɵɵdefineInjectable({
    token: BadgeStyle,
    factory: BadgeStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BadgeStyle, [{
    type: Injectable
  }], null, null);
})();
/**
 *
 * Badge represents people using icons, labels and images.
 *
 * [Live Demo](https://www.primeng.org/badge)
 *
 * @module badgestyle
 *
 */
var BadgeClasses;
(function (BadgeClasses) {
  /**
   * Class name of the root element
   */
  BadgeClasses["root"] = "p-badge";
})(BadgeClasses || (BadgeClasses = {}));

/**
 * Badge Directive is directive usage of badge component.
 * @group Components
 */
class BadgeDirective extends BaseComponent {
  /**
   * When specified, disables the component.
   * @group Props
   */
  disabled;
  /**
   * Size of the badge, valid options are "large" and "xlarge".
   * @group Props
   */
  badgeSize;
  /**
   * Size of the badge, valid options are "large" and "xlarge".
   * @group Props
   * @deprecated use badgeSize instead.
   */
  set size(value) {
    this._size = value;
    console.log('size property is deprecated and will removed in v18, use badgeSize instead.');
  }
  get size() {
    return this._size;
  }
  _size;
  /**
   * Severity type of the badge.
   * @group Props
   */
  severity;
  /**
   * Value to display inside the badge.
   * @group Props
   */
  value;
  /**
   * Inline style of the element.
   * @group Props
   */
  badgeStyle;
  /**
   * Class of the element.
   * @group Props
   */
  badgeStyleClass;
  id;
  badgeEl;
  _componentStyle = inject(BadgeStyle);
  get activeElement() {
    return this.el.nativeElement.nodeName.indexOf('-') != -1 ? this.el.nativeElement.firstChild : this.el.nativeElement;
  }
  get canUpdateBadge() {
    return this.id && !this.disabled;
  }
  constructor() {
    super();
  }
  ngOnChanges({
    value,
    size,
    severity,
    disabled,
    badgeStyle,
    badgeStyleClass
  }) {
    super.ngOnChanges({
      value,
      size,
      severity,
      disabled
    });
    if (disabled) {
      this.toggleDisableState();
    }
    if (!this.canUpdateBadge) {
      return;
    }
    if (severity) {
      this.setSeverity(severity.previousValue);
    }
    if (size) {
      this.setSizeClasses();
    }
    if (value) {
      this.setValue();
    }
    if (badgeStyle || badgeStyleClass) {
      this.applyStyles();
    }
  }
  ngAfterViewInit() {
    this.id = uuid('pn_id_') + '_badge';
    this.renderBadgeContent();
  }
  setValue(element) {
    const badge = element ?? this.document.getElementById(this.id);
    if (!badge) {
      return;
    }
    if (this.value != null) {
      if (hasClass(badge, 'p-badge-dot')) {
        removeClass(badge, 'p-badge-dot');
      }
      if (this.value && String(this.value).length === 1) {
        addClass(badge, 'p-badge-circle');
      } else {
        removeClass(badge, 'p-badge-circle');
      }
    } else {
      if (!hasClass(badge, 'p-badge-dot')) {
        addClass(badge, 'p-badge-dot');
      }
      removeClass(badge, 'p-badge-circle');
    }
    badge.innerHTML = '';
    const badgeValue = this.value != null ? String(this.value) : '';
    this.renderer.appendChild(badge, this.document.createTextNode(badgeValue));
  }
  setSizeClasses(element) {
    const badge = element ?? this.document.getElementById(this.id);
    if (!badge) {
      return;
    }
    if (this.badgeSize) {
      if (this.badgeSize === 'large') {
        addClass(badge, 'p-badge-lg');
        removeClass(badge, 'p-badge-xl');
      }
      if (this.badgeSize === 'xlarge') {
        addClass(badge, 'p-badge-xl');
        removeClass(badge, 'p-badge-lg');
      }
    } else if (this.size && !this.badgeSize) {
      if (this.size === 'large') {
        addClass(badge, 'p-badge-lg');
        removeClass(badge, 'p-badge-xl');
      }
      if (this.size === 'xlarge') {
        addClass(badge, 'p-badge-xl');
        removeClass(badge, 'p-badge-lg');
      }
    } else {
      removeClass(badge, 'p-badge-lg');
      removeClass(badge, 'p-badge-xl');
    }
  }
  renderBadgeContent() {
    if (this.disabled) {
      return null;
    }
    const el = this.activeElement;
    const badge = this.document.createElement('span');
    badge.id = this.id;
    badge.className = 'p-badge p-component';
    this.setSeverity(null, badge);
    this.setSizeClasses(badge);
    this.setValue(badge);
    addClass(el, 'p-overlay-badge');
    this.renderer.appendChild(el, badge);
    this.badgeEl = badge;
    this.applyStyles();
  }
  applyStyles() {
    if (this.badgeEl && this.badgeStyle && typeof this.badgeStyle === 'object') {
      for (const [key, value] of Object.entries(this.badgeStyle)) {
        this.renderer.setStyle(this.badgeEl, key, value);
      }
    }
    if (this.badgeEl && this.badgeStyleClass) {
      this.badgeEl.classList.add(...this.badgeStyleClass.split(' '));
    }
  }
  setSeverity(oldSeverity, element) {
    const badge = element ?? this.document.getElementById(this.id);
    if (!badge) {
      return;
    }
    if (this.severity) {
      addClass(badge, `p-badge-${this.severity}`);
    }
    if (oldSeverity) {
      removeClass(badge, `p-badge-${oldSeverity}`);
    }
  }
  toggleDisableState() {
    if (!this.id) {
      return;
    }
    if (this.disabled) {
      const badge = this.activeElement?.querySelector(`#${this.id}`);
      if (badge) {
        this.renderer.removeChild(this.activeElement, badge);
      }
    } else {
      this.renderBadgeContent();
    }
  }
  static ɵfac = function BadgeDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || BadgeDirective)();
  };
  static ɵdir = /*@__PURE__*/i0.ɵɵdefineDirective({
    type: BadgeDirective,
    selectors: [["", "pBadge", ""]],
    inputs: {
      disabled: [0, "badgeDisabled", "disabled"],
      badgeSize: "badgeSize",
      size: "size",
      severity: "severity",
      value: "value",
      badgeStyle: "badgeStyle",
      badgeStyleClass: "badgeStyleClass"
    },
    features: [i0.ɵɵProvidersFeature([BadgeStyle]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BadgeDirective, [{
    type: Directive,
    args: [{
      selector: '[pBadge]',
      providers: [BadgeStyle],
      standalone: true
    }]
  }], () => [], {
    disabled: [{
      type: Input,
      args: ['badgeDisabled']
    }],
    badgeSize: [{
      type: Input
    }],
    size: [{
      type: Input
    }],
    severity: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    badgeStyle: [{
      type: Input
    }],
    badgeStyleClass: [{
      type: Input
    }]
  });
})();
/**
 * Badge is a small status indicator for another element.
 * @group Components
 */
class Badge extends BaseComponent {
  /**
   * Class of the element.
   * @group Props
   */
  styleClass = input();
  /**
   * Inline style of the element.
   * @group Props
   */
  style = input();
  /**
   * Size of the badge, valid options are "large" and "xlarge".
   * @group Props
   */
  badgeSize = input();
  /**
   * Size of the badge, valid options are "large" and "xlarge".
   * @group Props
   */
  size = input();
  /**
   * Severity type of the badge.
   * @group Props
   */
  severity = input();
  /**
   * Value to display inside the badge.
   * @group Props
   */
  value = input();
  /**
   * When specified, disables the component.
   * @group Props
   */
  badgeDisabled = input(false, {
    transform: booleanAttribute
  });
  _componentStyle = inject(BadgeStyle);
  /**
   * Computes the container class for the badge element based on its properties.
   * @returns An object representing the CSS classes to be applied to the badge container.
   */
  containerClass = computed(() => {
    let classes = 'p-badge p-component';
    if (isNotEmpty(this.value()) && String(this.value()).length === 1) {
      classes += ' p-badge-circle';
    }
    if (this.badgeSize() === 'large') {
      classes += ' p-badge-lg';
    } else if (this.badgeSize() === 'xlarge') {
      classes += ' p-badge-xl';
    } else if (this.badgeSize() === 'small') {
      classes += ' p-badge-sm';
    }
    if (isEmpty(this.value())) {
      classes += ' p-badge-dot';
    }
    if (this.styleClass()) {
      classes += ` ${this.styleClass()}`;
    }
    if (this.severity()) {
      classes += ` p-badge-${this.severity()}`;
    }
    return classes;
  });
  static ɵfac = /*@__PURE__*/(() => {
    let ɵBadge_BaseFactory;
    return function Badge_Factory(__ngFactoryType__) {
      return (ɵBadge_BaseFactory || (ɵBadge_BaseFactory = i0.ɵɵgetInheritedFactory(Badge)))(__ngFactoryType__ || Badge);
    };
  })();
  static ɵcmp = /*@__PURE__*/i0.ɵɵdefineComponent({
    type: Badge,
    selectors: [["p-badge"]],
    hostVars: 6,
    hostBindings: function Badge_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵstyleMap(ctx.style());
        i0.ɵɵclassMap(ctx.containerClass());
        i0.ɵɵstyleProp("display", ctx.badgeDisabled() && "none");
      }
    },
    inputs: {
      styleClass: [1, "styleClass"],
      style: [1, "style"],
      badgeSize: [1, "badgeSize"],
      size: [1, "size"],
      severity: [1, "severity"],
      value: [1, "value"],
      badgeDisabled: [1, "badgeDisabled"]
    },
    features: [i0.ɵɵProvidersFeature([BadgeStyle]), i0.ɵɵInheritDefinitionFeature],
    decls: 1,
    vars: 1,
    template: function Badge_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵtext(0);
      }
      if (rf & 2) {
        i0.ɵɵtextInterpolate(ctx.value());
      }
    },
    dependencies: [CommonModule, SharedModule],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Badge, [{
    type: Component,
    args: [{
      selector: 'p-badge',
      template: `{{ value() }}`,
      standalone: true,
      imports: [CommonModule, SharedModule],
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [BadgeStyle],
      host: {
        '[class]': 'containerClass()',
        '[style.display]': 'badgeDisabled() && "none"',
        '[style]': 'style()'
      }
    }]
  }], null, null);
})();
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassDebugInfo(Badge, {
    className: "Badge",
    filePath: "badge.ts",
    lineNumber: 255
  });
})();
class BadgeModule {
  static ɵfac = function BadgeModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || BadgeModule)();
  };
  static ɵmod = /*@__PURE__*/i0.ɵɵdefineNgModule({
    type: BadgeModule
  });
  static ɵinj = /*@__PURE__*/i0.ɵɵdefineInjector({
    imports: [Badge, SharedModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BadgeModule, [{
    type: NgModule,
    args: [{
      imports: [Badge, BadgeDirective, SharedModule],
      exports: [Badge, BadgeDirective, SharedModule]
    }]
  }], null, null);
})();
(function () {
  (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(BadgeModule, {
    imports: [Badge, BadgeDirective, SharedModule],
    exports: [Badge, BadgeDirective, SharedModule]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Badge, BadgeClasses, BadgeDirective, BadgeModule, BadgeStyle };
